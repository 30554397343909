<!--补充信息-->
<template>
  <div>
    <div class="tableTitle" style="margin-top:0px;">
      <span class="title">
        授权信息
      </span>
    </div>
    <div class="fromDiv">
      <div class="formLine">
        <FromLabel label="法人授权书" :showIcon="true"></FromLabel>
        <div class="allWidth" v-if="reRender">
          <Input v-if="!supplementary.license_file_key_url" clearable @on-clear="clearUploadFile" readonly class="iviewIptWidth307 clearBtn" placeholder="仅支持jpg.jepg.png.pdf等格式的图片/文件的图片">
            <span slot="append" class="finger" >
              <newformUpload label="上传" @get-key="getKey(...arguments, 'license_file_key')" :data="data"></newformUpload>
            </span>
          </Input>
          <Input v-model="company_name" v-if="supplementary.license_file_key_url" clearable @on-clear="clearUploadFile" readonly class="iviewIptWidth307 clearBtn" placeholder="仅支持jpg.jepg.png.pdf等格式的图片/文件的图片">
            <span slot="append" class="finger" >
              <newformUpload label="上传" @get-key="getKey(...arguments, 'license_file_key')" :data="data"></newformUpload>
            </span>
          </Input>
          <span class="lookupImg" v-if="supplementary.license_file_key && company_name" @click="openFile">{{ company_name }}-法人授权书</span>
          <span class="lookupImg" v-if="supplementary.license_file_key && !company_name" @click="openFile">-法人授权书</span>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="被授权人姓名" :showIcon="true"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model="supplementary.name"></i-input>
        </div>
        <FromLabel label="证件号码" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model="supplementary.certid"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="手机号" :showIcon="true"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model="supplementary.phone"></i-input>
          <span class="spin">(该手机号将会接收<br/>系统消息提醒)</span>
        </div>
        <FromLabel label="邮箱" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model="supplementary.email"></i-input>
          <span class="spin">(该邮箱号将会接收<br/>系统消息提醒)</span>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="授权日期" :showIcon="true"></FromLabel>
        <div class="formFlIpt578 fl">
          <DatePicker type="date" placeholder="请选择" class="iviewIptWidth165" :value="supplementary.license_start_date" @on-change="changeTime($event, 1)"></DatePicker>
          <span class="afterWords14">至</span>
          <DatePicker type="date" placeholder="请选择" class="iviewIptWidth165" :value="supplementary.license_end_date" @on-change="changeTime($event, 2)" :options="options" :disabled="supplementary.is_long_term == 1"></DatePicker>
          <Checkbox v-model="supplementary.is_long_term" @on-change="onChange" :true-value="1" :false-value="0" style="margin-left:20px;">长期</Checkbox>
        </div>
        <FromLabel label="授权区域"></FromLabel>
        <div class="formFrIpt fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model="supplementary.license_area"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone moreHeight">
        <div class="formLabel fl">
          <div>
            <span class="icon"></span>
            <span>产品线</span>
          </div>
        </div>
        <div class="allWidth">
          <i-input type="textarea" :autosize="{ minRows: 2, maxRows: 2 }" placeholder="请输入" maxlength="1000" show-word-limit class="iviewIptWidth1075" v-model="supplementary.product_line"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="备注"></FromLabel>
        <div class="allWidth">
          <i-input type="text" placeholder="请输入" maxlength="200" show-word-limit class="iviewIptWidth1075" v-model="supplementary.other"></i-input>
        </div>
      </div>
    </div>
    <div class="tableTitle">
      <span class="title">
         付款信息（备注：该信息与回款有关，请如实填写贵司银行账号信息）
      </span>
    </div>
    <div class="fromDiv">
      <div class="formLine">
        <FromLabel label="发票抬头" :showIcon="true"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model="supplementary.invoice_head"></i-input>
        </div>
        <FromLabel label="税号" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model="supplementary.invoice_no"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="开户银行" :showIcon="true"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model="supplementary.invoice_bank"></i-input>
        </div>
        <FromLabel label="银行账号" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <i-input type="number" placeholder="请输入" class="iviewIptWidth307" v-model="supplementary.invoice_bankno"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="企业地址"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model="supplementary.company_address"></i-input>
        </div>
        <FromLabel label="企业电话"></FromLabel>
        <div class="formFrIpt fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model="supplementary.company_phone"></i-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FromLabel from '@/components/formLabel'
import newformUpload from '@/components/newformUpload'
export default {
  name: 'additionalInfo',
  components: {
    FromLabel,
    newformUpload
  },
  data() {
    return {
      company_name: '',
      options: {},
      reRender: true,
      // 补充信息
      supplementary: {
        company_name: '',
      },
      data: {
        table_type: 7
      }
    }
  },
  props: {
    supplementary_props: Object
  },
  mounted() {
    this.$root.eventHub.$off('supplier_id')
    this.$root.eventHub.$on('supplier_id', supplier_id => {
      if (supplier_id) {
        console.log(this.data, 'data')
        console.log(supplier_id)
        this.$set(this.data, 'supplier_id', supplier_id)
        this.$set(this.data, 'table_type', 7)
      } else {
        this.$set(this.data, 'table_type', 7)
      }
    })
  },
  watch: {
    // 初始化 supplementary 值
    supplementary_props: {
      handler(newValue, oldValue) {
        console.log(newValue, oldValue, '7890678---')
        // 如果截止日期为'9999-12-31'则置空
        if (newValue.license_end_date == '9999-12-31') {
          newValue.license_end_date = null
        }
        // 处理单选-长期
        if (!newValue.is_long_term) {
          newValue.is_long_term = 0
        }
        this.supplementary = newValue
      },
      deep: true,
      immediate: true
    },
    // 侦听对象-深度侦听-子传父
    supplementary: {
      handler(newValue, oldValue) {
        let syncField = []
          if (newValue.company_name != oldValue.company_name) {
          syncField = ['company_name', newValue.company_name]
        }
        this.$emit('information', 'supplementary', this.supplementary, syncField)
        if(this.supplementary.company_name) {
          this.company_name = this.supplementary.company_name
        }
      },
      deep: true
    }
  },
  methods: {
    deep() {
      this.supplementary = JSON.parse(JSON.stringify(this.supplementary))
    },
    // 选择 长期 时清空截止日期
    onChange() {
      this.supplementary.license_end_date = ''
    },
    changeDate(field, e) {
      this.supplementary[field] = e
    },
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        this.supplementary.license_start_date = e
        if (e > this.supplementary.license_end_date) {
          this.supplementary.license_end_date = ''
        }
        this.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.supplementary.license_start_date) - 86400000
          }
        }
      } else {
        this.supplementary.license_end_date = e
      }
    },
    // 打开上传的文件
    openFile() {
      window.open(this.supplementary.license_file_key_url)
    },
    // 清空上传文件
    clearUploadFile() {
      delete this.supplementary.license_file_key
    },
    // 获取文件上传成功后的 key 值
    getKey(key, file_name, url, field) {
      this.reRender = false
      if (field === 'license_file_key') {
        this.supplementary.license_file_key = key
        this.supplementary.license_file_key_url = url
        this.supplementary.license_file_name = file_name
      }
      this.reRender = true
    }
  }
}
</script>

<style scoped lang="less">
.lookupImg {
}
.clearBtn {
  /deep/.ivu-input-icon {
    position: absolute;
    top: 50%;
    right: 68px;
    transform: translate(50%, -50%);
  }
}
.topDiv {
  height: 36px;
  margin-bottom: 20px;
}

.marginRight10 {
  margin-right: 10px;
}

.tableTitle {
  margin: 20px 0 20px 0;

  .title {
    color: #525b6d;
    font-size: 18px;
    font-weight: 600;
  }
}

.moreHeight {
  height: 100px;
}

.formLabel {
  height: 100px !important;
  line-height: 100px !important;
}
.spin {
  margin-left: 10px;
  color: #525b6d;
  font-size: 14px;
}
</style>
