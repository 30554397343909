<!--生产备案凭证-->
<template>
  <div>
    <div class="fromDiv">
      <div class="formLine">
        <FromLabel label="备案凭证" :showIcon="true"></FromLabel>
        <div class="allWidth" v-if="reRender">
          <Input v-model="production_registration_license.license_file_name" clearable @on-clear="clearUploadFile" readonly class="iviewIptWidth307 clearBtn" placeholder="仅支持jpg.jepg.png.pdf等格式的图片/文件">
            <span slot="append" class="finger">
              <newformUpload label="上传" @get-key="getKey(...arguments, 'license_file_key')" :data="data"></newformUpload>
            </span>
          </Input>
          <!-- <span class="lookupImg" @click="openFile">{{ production_registration_license.license_file_name }}</span> -->
          <span class="lookupImg" v-if="production_registration_license.company_name && this.production_registration_license.license_file_key" @click="openFile">{{ production_registration_license.company_name }}-生产备案凭证</span>
          <span class="lookupImg" v-else-if="!production_registration_license.company_name && this.production_registration_license.license_file_key">生产备案凭证</span>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="备案号" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model="production_registration_license.production_registration_license_code"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="企业名称" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <i-input placeholder="请输入" class="iviewIptWidth307" :disabled="Boolean(is_approved)" v-model="production_registration_license.company_name"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="法人" :showIcon="true"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model="production_registration_license.legal_person"></i-input>
        </div>
        <FromLabel label="企业负责人" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model="production_registration_license.incharge_person"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone moreHeight">
        <div class="formLabel fl">
          <div>
            <span class="icon">*</span>
            <span>生产地址</span>
          </div>
        </div>
        <div class="allWidth">
          <i-input type="textarea" :autosize="{ minRows: 2, maxRows: 2 }" maxlength="1000" show-word-limit placeholder="请输入" class="iviewIptWidth1075" v-model="production_registration_license.production_address"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone moreHeight">
        <div class="formLabel fl">
          <div>
            <span class="icon">*</span>
            <span>住所</span>
          </div>
        </div>
        <div class="allWidth">
          <i-input type="textarea" :autosize="{ minRows: 2, maxRows: 2 }" maxlength="1000" show-word-limit placeholder=" 请输入" class="iviewIptWidth1075" v-model="production_registration_license.residence"> </i-input>
        </div>
      </div>
      <div class="formLine borderTopNone moreHeight">
        <div class="formLabel fl">
          <div>
            <span class="icon">*</span>
            <span>生产范围</span>
          </div>
        </div>
        <div class="allWidth">
          <i-input type="textarea" :autosize="{ minRows: 2, maxRows: 2 }" maxlength="1000" show-word-limit placeholder=" 请输入" class="iviewIptWidth1075" v-model="production_registration_license.production_scope"> </i-input>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="备案部门"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model="production_registration_license.license_department"></i-input>
        </div>
        <FromLabel label="备案日期" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <DatePicker type="date" placeholder="请选择" class="iviewIptWidth307" :value="production_registration_license.license_date" @on-change="changeDate('license_date', $event)"></DatePicker>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FromLabel from '@/components/formLabel'
import newformUpload from '@/components/newformUpload'
export default {
  name: 'productionRecord',
  components: {
    FromLabel,
    newformUpload
  },
  data() {
    return {
      reRender: true,
      // 生产备案凭证
      production_registration_license: {},
      data: {
        table_type: 4
      }
    }
  },
  mounted() {
    this.$root.eventHub.$off('supplier_id')
    this.$root.eventHub.$on('supplier_id', supplier_id => {
      if (supplier_id) {
        this.$set(this.data, 'supplier_id', supplier_id)
        this.$set(this.data, 'table_type', 4)
      } else {
        this.$set(this.data, 'table_type', 4)
      }
    })
  },
  props: {
    production_registration_license_props: Object,
    is_approved: Number,
  },
  watch: {
    // 初始化 business_license 值
    production_registration_license_props: {
      handler(newValue, oldValue) {
        this.production_registration_license = newValue
      },
      deep: true,
      immediate: true
    },
    // 侦听对象-深度侦听-子传父
    productionRegistrationLicense: {
      handler(newValue, oldValue) {
        let syncField = []
        // 企业名称
        if (newValue.company_name != oldValue.company_name) {
          syncField = ['company_name', newValue.company_name]
        }
        // 法人
        if (newValue.legal_person != oldValue.legal_person) {
          syncField = ['legal_person', newValue.legal_person]
        }
        // 住所
        if (newValue.residence != oldValue.residence) {
          syncField = ['residence', newValue.residence]
        }
        // 生产地址
        // if (newValue.production_address != oldValue.production_address) {
        //   syncField = ['production_address', newValue.production_address]
        // }
        // 生产范围
        if (newValue.production_scope != oldValue.production_scope) {
          syncField = ['production_scope', newValue.production_scope]
        }
        // 企业负责人
        if (newValue.incharge_person != oldValue.incharge_person) {
          syncField = ['incharge_person', newValue.incharge_person]
        }
        this.$emit('information', 'production_registration_license', this.production_registration_license, syncField)
      },
      deep: true
    }
  },
  // 解决深度监听新旧值打印一样问题
  computed: {
    productionRegistrationLicense() {
      return JSON.parse(JSON.stringify(this.production_registration_license))
    }
  },
  methods: {
    changeName() {
      this.production_registration_license.license_file_name = this.production_registration_license.company_name
    },
    changeDate(field, e) {
      this.production_registration_license[field] = e
    },
    // 打开上传的文件
    openFile() {
      window.open(this.production_registration_license.license_file_key_url)
    },
    // 清空上传文件
    clearUploadFile() {
      delete this.production_registration_license.license_file_key
    },
    // 获取文件上传成功后的 key 值
    getKey(key, file_name, url, field) {
      this.reRender = false
      if (field === 'license_file_key') {
        this.production_registration_license.license_file_key = key
        this.production_registration_license.license_file_key_url = url
        this.production_registration_license.license_file_name = file_name
        // if (!this.production_registration_license.company_name) {
        //   this.production_registration_license.license_file_name = ''
        // } else {
        //   this.production_registration_license.license_file_name = this.production_registration_license.company_name
        // }
      }
      this.reRender = true
    }
  }
}
</script>

<style scoped lang="less">
.lookupImg {
}

.clearBtn {
  /deep/.ivu-input-icon {
    position: absolute;
    top: 50%;
    right: 68px;
    transform: translate(50%, -50%);
  }
}
.topDiv {
  height: 36px;
  margin-bottom: 20px;
}

.marginRight10 {
  margin-right: 10px;
}

.tableTitle {
  margin: 17px 0 20px 0;

  .title {
    color: #525b6d;
    font-size: 18px;
    font-weight: 600;
  }
}

.moreHeight {
  height: 100px;
}

.formLabel {
  height: 100px !important;
  line-height: 100px !important;
}
</style>
