<!--营业执照信息-->
<template>
  <div>
    <div class="topDiv">

      <!-- <a class="longTextBtn finger btnReset " download href="/static/经销商导入模板.xlsx" v-if="type == 1">模板下载</a> -->
      <!-- <a class="longTextBtn finger btnReset " download href="/static/境内厂家直销导入模板.xlsx" v-if="type == 2">模板下载</a> -->
      <span class="longTextBtn finger btnReset " @click="downloadTemlpate(type)">模板下载</span>
      <span class="longTextBtn finger btnReset marginLeft20">
        <UploadFile label="模板导入" :multiple="false" :apiSrc="apiSrc" :supplier_type="String(type)" @importSuccess="importSuccess"></UploadFile>
      </span>
      <!-- <span class="longTextBtn finger btnReset marginLeft20" v-else> 模版导入 </span> -->
      <span class="remarksAlink">企查查官网：<a href="https://www.qcc.com/" target="_blank">https://www.qcc.com/</a> 操作简要：点击进入网站-在查询栏输入公司名称-找到该公司的营业执照信息，复制粘贴即可完成信息维护。</span>
    </div>
    <div class="fromDiv">
      <div class="formLine">
        <FromLabel label="营业执照" :showIcon="true"></FromLabel>
        <div class="formFlIpt578 fl" v-if="reRender">
          <Input v-model="business_license.license_file_name" clearable @on-clear="clearUploadFile('license_file_key')" readonly class="iviewIptWidth307 clearBtn" placeholder="仅支持jpg.jepg.png.pdf等格式的图片/文件">
            <span slot="append" class="finger">
              <newformUpload label="上传" @get-key="getKey(...arguments, 'license_file_key')" :data="data1"></newformUpload>
            </span>
          </Input>
          <span class="lookupImg" v-show="business_license.company_name && business_license.license_file_key" @click="openFile('license_file_name')">{{ business_license.company_name }}-营业执照</span>
          <span class="lookupImg" v-show="!business_license.company_name && business_license.license_file_key" @click="openFile('license_file_name')">-营业执照</span>
        </div>
        <FromLabel label="合同"></FromLabel>
        <div class="formFrIpt fl" v-if="reRender">
          <Input v-model="business_license.contract_file_name" clearable @on-clear="clearUploadFile('invoice_head')" readonly class="iviewIptWidth307 clearBtn" placeholder="仅支持jpg.jepg.png.pdf等格式的图片/文件">
            <span slot="append" class="finger">
              <newformUpload label="上传" @get-key="getKey(...arguments, 'invoice_head')" :data="data2"></newformUpload>
            </span>
          </Input>
          <!-- <span class="lookupImg" @click="openFile('contract_file_name')">{{ business_license.contract_file_name }}</span> -->
          <span class="lookupImg" v-if="business_license.company_name && business_license.contract_file_key" @click="openFile('contract_file_name')">{{ business_license.company_name }}-合同</span>
          <span class="lookupImg" v-else-if="!business_license.company_name && business_license.contract_file_key" @click="openFile('license_file_name')">-合同</span>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="企业名称" :showIcon="true"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" :disabled="Boolean(is_approved)" v-model="business_license.company_name"></i-input>
        </div>
        <FromLabel label="国外注册公司" v-if="type === 1"></FromLabel>
        <div class="formFrIpt fl" v-if="type === 1">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model="business_license.foreign_company"></i-input>
        </div>
        <FromLabel label="统一社会信用代码" :showIcon="true" v-if="type === 2"></FromLabel>
        <div class="formFrIpt fl" v-if="type === 2">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model="business_license.unified_code"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="类型"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model="business_license.company_type"></i-input>
        </div>
        <FromLabel label="统一社会信用代码" :showIcon="true" v-if="type === 1"></FromLabel>
        <div class="formFrIpt fl" v-if="type === 1">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model="business_license.unified_code"></i-input>
        </div>
      </div>
      <!-- <div class="formLine borderTopNone ">
        <FromLabel label="公司地址"></FromLabel>
        <div class="allWidth">
          <Select filterable clearable @on-change="changeProvince" v-model="business_license.province" class="iviewIptWidth165 marginRight10">
            <Option v-for="(item, index) in provinceList" :value="item.area_code" :key="index">{{ item.area_name }}</Option>
          </Select>
          <Select filterable clearable v-model="business_license.city" class="iviewIptWidth165 marginRight10">
            <Option v-for="(item, index) in cityList" :value="item.area_code" :key="index">{{ item.area_name }}</Option>
          </Select>
          <i-input placeholder="请输入" class="iviewIptWidth725" v-model="business_license.address"></i-input>
        </div>
      </div> -->
      <div class="formLine borderTopNone ">
        <FromLabel label="法人" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model="business_license.legal_person"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="成立日期"></FromLabel>
        <div class="formFlIpt578 fl">
          <DatePicker type="date" placeholder="请选择" class="iviewIptWidth307" :value="business_license.establish_date" @on-change="changeDate('establish_date', $event)"></DatePicker>
        </div>
        <FromLabel label="注册资本"></FromLabel>
        <div class="formFrIpt fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model="business_license.registered_capital"></i-input>
          <span class="afterWords10">万元</span>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="营业期限" :showIcon="true"></FromLabel>
        <div class="formFlIpt578 fl">
          <DatePicker type="date" placeholder="请选择" class="iviewIptWidth165" :value="business_license.business_start_date" @on-change="changeTime($event, 1)"></DatePicker>
          <span class="afterWords14">至</span>
          <DatePicker type="date" placeholder="请选择" class="iviewIptWidth165" :value="business_license.business_end_date" @on-change="changeTime($event, 2)" :options="options" :disabled="business_license.is_long_term == 1"></DatePicker>
          <Checkbox v-model="endData" @on-change="onChange" style="margin-left:20px;">长期</Checkbox>
        </div>
        <FromLabel label="登记日期" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <DatePicker type="date" placeholder="请选择" class="iviewIptWidth307" :value="business_license.registration_date" @on-change="changeDate('registration_date', $event)"></DatePicker>
        </div>
      </div>
      <div class="formLine borderTopNone moreHeight">
        <div class="formLabel fl">
          <div>
            <span class="icon">*</span>
            <span>经营范围</span>
          </div>
        </div>
        <div class="allWidth">
          <i-input type="textarea" :autosize="{ minRows: 3, maxRows: 3 }" placeholder="请输入" class="iviewIptWidth1075" maxlength="1000" show-word-limit v-model="business_license.business_scope"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="住所" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <i-input type="text" placeholder="请输入" class="iviewIptWidth1075" maxlength="200" show-word-limit v-model="business_license.residence"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="备注"></FromLabel>
        <div class="allWidth">
          <i-input type="text" placeholder="请输入" class="iviewIptWidth1075" maxlength="200" show-word-limit v-model="business_license.other"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="资料维护" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <CheckboxGroup v-model="business_license.data_type" v-if="type === 1">
            <Checkbox label="1">经营许可证</Checkbox>
            <Checkbox label="2">经营备案凭证</Checkbox>
          </CheckboxGroup>
          <CheckboxGroup v-model="business_license.data_type" v-if="type === 2">
            <Checkbox label="3">生产许可证</Checkbox>
            <Checkbox label="4">生产备案凭证</Checkbox>
            <Checkbox label="1">经营许可证</Checkbox>
            <Checkbox label="2">经营备案凭证</Checkbox>
          </CheckboxGroup>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FromLabel from '@/components/formLabel'
import newformUpload from '@/components/newformUpload'
import UploadFile from './uploadFile'
import http from '@/service/baseUrl'
export default {
  name: 'businessLicenseInfo',
  components: {
    FromLabel,
    newformUpload,
    UploadFile
  },
  data() {
    return {
      endData: false,
      options: {},
      // 模版地址
      apiSrc: '',
      reRender: true,
      // 市
      // cityList: [],
      // 省
      // provinceList: [],
      // 营业执照信息
      business_license: {},
      data1: {
        table_type: 1
      },
      data2: {
        table_type: 2
      }
    }
  },
  props: {
    type: Number,
    business_license_props: Object,
    is_approved: Number,
  },
  watch: {
    // 初始化 business_license 值
    business_license_props: {
      handler(newValue, oldValue) {
        // 如果截止日期为'9999-12-31'则置空
        if (newValue.business_end_date == '9999-12-31') {
          newValue.business_end_date = null
        }
        // 处理单选-长期
        if (!newValue.is_long_term) {
          newValue.is_long_term = 0
        }
        this.business_license = newValue
        this.endData = this.business_license.is_long_term == 1
      },
      deep: true,
      immediate: true
    },
    // 侦听对象-深度侦听-子传父
    businessLicense: {
      handler(newValue, oldValue) {
        let syncField = []
        // 企业名称
        if (newValue.company_name != oldValue.company_name) {
          syncField = ['company_name', newValue.company_name]
        }
        // 法人
        if (newValue.legal_person != oldValue.legal_person) {
          syncField = ['legal_person', newValue.legal_person]
        }
        // 住所
        if (newValue.residence != oldValue.residence) {
          syncField = ['residence', newValue.residence]
        }
        this.$emit('information', 'business_license', this.business_license, syncField)
      },
      deep: true
    }
  },
  // 解决深度监听新旧值打印一样问题
  computed: {
    businessLicense() {
      return JSON.parse(JSON.stringify(this.business_license))
    }
  },
  created() {
    this.apiSrc = http.http + this.$api.importSupplier.url
  },
  mounted() {
    this.$root.eventHub.$off('supplier_id')
    this.$root.eventHub.$on('supplier_id', supplier_id => {
      if (supplier_id) {
        this.$set(this.data1, 'supplier_id', supplier_id)
        this.$set(this.data1, 'table_type', 1)
        this.$set(this.data2, 'supplier_id', supplier_id)
        this.$set(this.data2, 'table_type', 2)
      } else {
        this.$set(this.data1, 'table_type', 1)
        this.$set(this.data2, 'table_type', 2)
      }
    })
  },
  methods: {
    changeName() {
      // console.log(this.business_license.license_file_key)
      // console.log(this.business_license.company_name)
      if (this.business_license.license_file_key) {
        this.business_license.license_file_name = this.business_license.company_name
      }
      // this.business_license.license_file_name = this.business_license.company_name
      // this.business_license.contract_file_name = this.business_license.company_name
    },
    importSuccess(res) {
      this.$emit('importSuccess', res)
    },
    // 模版下载  思路1：模版放置 静态文件 目录中直接下载；思路2：使用axios调接口从服务器上下载
    downloadTemlpate(supplier_type) {
      let fileName = supplier_type == 1 ? '经销商导入模板.xlsx' : '境内厂家直销导入模板.xlsx'
      // 获取二进制文件流
      this.$http.downFile(this.$api.supplierTemlpateDownload, { supplier_type: String(supplier_type) }).then(res => {
        // 创建 Blob 对象存储二进制文件流以便读取
        let blob = new Blob([res.data], { type: 'application/x-xlsx' }) // 参数1：新对象的内容；参数2：文件类型
        // 创建 a 标签
        const aLink = document.createElement('a')
        // 设置 href 属性
        aLink.href = URL.createObjectURL(blob)
        // 设置下载的文件 名称 和 扩展名
        aLink.download = fileName
        // 模拟点击
        aLink.click()
      })
    },
    // 选择 长期 时清空截止日期
    onChange(e) {
      if (e) {
        this.business_license.is_long_term = 1
      } else {
        this.business_license.is_long_term = 0
      }
      this.changeDate('business_end_date', '')
    },
    // 地区下拉列表-市
    // changeProvince(foo) {
    //   if (foo === undefined) {
    //     return
    //   }
    //   let data = {
    //     parent_id: String(foo)
    //   }
    //   this.$http.get(this.$api.zoneList, data).then(res => {
    //     this.cityList = res.data
    //   })
    // },
    // 地区下拉列表-省
    // zoneList() {
    //   let data = {
    //     parent_id: '0'
    //   }
    //   this.$http.get(this.$api.zoneList, data).then(res => {
    //     this.provinceList = res.data
    //   })
    // },
    // 打开上传的文件
    openFile(field) {
      switch (field) {
        case 'license_file_name':
          window.open(this.business_license.license_file_key_url)
          break
        case 'contract_file_name':
          window.open(this.business_license.contract_file_key_url)
          break
        default:
          break
      }
    },
    // 清空上传文件
    clearUploadFile(field) {
      switch (field) {
        case 'license_file_key':
          this.business_license.license_file_key = ''
          this.business_license.license_file_key_url = ''
          this.business_license.license_file_name = ''
          console.log(this.business_license)
          break
        case 'invoice_head': // 合同
          this.business_license.contract_file_key = ''
          this.business_license.contract_file_key_url = ''
          this.business_license.contract_file_name = ''
          // delete this.business_license.contract_file_key
          // delete this.business_license.contract_file_key_url
          // delete this.business_license.contract_file_name
          break
      }
    },
    // 获取文件上传成功后的 key 值
    getKey(key, file_name, url, field) {
      this.reRender = false
      console.log(key, file_name, url, field, 'field')
      switch (field) {
        case 'license_file_key': // 营业执照
          this.business_license.license_file_key = key
          this.business_license.license_file_key_url = url
          this.business_license.license_file_name = file_name
          break
        case 'invoice_head': // 合同
          this.business_license.contract_file_key = key
          this.business_license.contract_file_key_url = url
          this.business_license.contract_file_name = file_name
          break
        default:
          break
      }
      this.reRender = true
    },
    // 改变日期
    changeDate(field, e) {
      this.business_license[field] = e
    },
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        this.business_license.business_start_date = e
        if (e > this.business_license.business_end_date) {
          this.business_license.business_end_date = ''
        }
        this.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.business_license.business_start_date) - 86400000
          }
        }
      } else {
        this.business_license.business_end_date = e
      }
    }
  }
}
</script>

<style scoped lang="less">
.iviewIptWidth725 {
  width: 725px !important;
}

.lookupImg {
  width: 200px;
}

.clearBtn {
  /deep/.ivu-input-icon {
    position: absolute;
    top: 50%;
    right: 68px;
    transform: translate(50%, -50%);
  }
}

.topDiv {
  height: 36px;
  margin-bottom: 20px;
}

.marginRight10 {
  margin-right: 10px;
}

.moreHeight {
  height: 150px;
}

.formLabel {
  height: 150px !important;
  line-height: 150px !important;
}
.remarksAlink {
  margin-left: 20px;
}
</style>
