<template>
  <div class="pagePadding">
    <ul class="topNavDiv">
      <li v-for="(item, index) in navFrist" :key="index" :class="{ clickActive: clickIndex == item.type, finger: true }" @click="clickNav(item.type)">
        {{ item.name }}
        <div class="linear" v-if="clickIndex == item.type"></div>
      </li>
    </ul>
    <div class="btmDiv">
      <div class="formDiv">
        <!--营业执照信息-->
        <BusinessLicenseInfo ref="businessLicenseInfo" v-show="clickIndex == 1" :is_approved="is_approved" :business_license_props="business_license" @importSuccess="importSuccess" @information="information" :type="type"></BusinessLicenseInfo>
        <!--生产许可证（经销商 无此）-->
        <ProductionLicense v-show="clickIndex == 2" :is_approved="is_approved" :production_license_props="production_license" @information="information"></ProductionLicense>
        <!--生产备案凭证（经销商 无此）-->
        <ProductionRecord v-show="clickIndex == 3" :is_approved="is_approved" :production_registration_license_props="production_registration_license" @information="information"></ProductionRecord>
        <!--经营许可证-->
        <OperatingLicense v-show="clickIndex == 4" :is_approved="is_approved" :operating_license_props="operating_license" @information="information" :insertAble="insertAble"></OperatingLicense>
        <!--经营备案凭证-->
        <RecordCertificate v-show="clickIndex == 5" :is_approved="is_approved" :registration_license_props="registration_license" @information="information" :insertAble="insertAble"></RecordCertificate>
        <!--补充信息-->
        <AdditionalInfo ref="additionalInfo" v-show="clickIndex == 6" :supplementary_props="supplementary" @information="information" :operation="operation"></AdditionalInfo>
      </div>
      <div class="saveDiv clearfix">
        <div class="pageBtn finger btnSure fr marginLeft20" @click="beforeCommit" v-if="clickIndex == 6" v-show="modifyAble">提交</div>
        <div v-if="clickIndex == 6" v-show="modifyAble" class="noticeText">(点击提交,将信息提交至管理端等待审核)</div>
        <template>
          <div class="pageBtn finger btnSure fr marginLeft20" @click="beforeSave" v-if="navFrist.length === 1">下一步</div>
          <div class="pageBtn finger btnSure fr marginLeft20" @click="beforeSave" v-else v-show="modifyAble">保存</div>
        </template>
      </div>
    </div>

    <!-- 是否确认提交 -->
    <Modal v-model="isCommit" width="400" :footer-hide="true" :closable="false" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <p>是否确认提交审核？</p>
        <div>
          <span class="pageBtn finger btnSure" @click="save('1')">确定</span>
          <span class="pageBtn finger btnCancle" @click="isCommit = false">取消</span>
        </div>
      </div>
    </Modal>

    <!-- 选择供应商类型 -->
    <Modal closable v-model="fristComingStatus" label-position="left" title="请选择公司类型" width="505" class-name="vertical-center-modal">
      <div v-if="fristComingStatus" style="height: 190px">
        <span style="margin-right: 12px; float: left; color: #000">请选择公司类型：</span>
        <RadioGroup v-model="supplierType" class="fl">
          <Radio label="1">经销商</Radio>
          <Radio label="2" style="margin-left: 23px">境内厂家直销</Radio>
        </RadioGroup>
      </div>
      <div slot="footer">
        <span class="longTextBtn btnSure finger" @click="nextStep">下一步</span>
      </div>
    </Modal>
  </div>
</template>

<script>
// 营业执照信息
import BusinessLicenseInfo from './supplierChild/businessLicenseInfo'
// 生产许可证（经销商 无此）
import ProductionLicense from './supplierChild/productionLicense'
// 生产备案凭证（经销商 无此）
import ProductionRecord from './supplierChild/productionRecord'
// 经营许可证
import OperatingLicense from './supplierChild/operatingLicense'
// 经营备案凭证
import RecordCertificate from './supplierChild/recordCertificate'
// 补充信息
import AdditionalInfo from './supplierChild/additionalInfo'
import { debounce } from "@/service/utils";
export default {
  name: 'supplierManage',
  components: {
    BusinessLicenseInfo,
    ProductionLicense,
    ProductionRecord,
    OperatingLicense,
    RecordCertificate,
    AdditionalInfo
  },
  data() {
    return {
      // 营业执照信息
      business_license: {},
      // 生产许可证
      production_license: {},
      // 生产备案凭证
      production_registration_license: {},
      // 经营许可证
      operating_license: {},
      // 经营备案凭证
      registration_license: {},
      // 补充信息
      supplementary: {},
      // 最初请求到的数据
      originalData: {},

      // 检验标记: 各Tab页是否校验无误，1无误，0有误
      // [营业执照信息, 生产许可证, 生产备案凭证, 经营许可证, 经营备案凭证, 补充信息]
      verifyIsSend: [0, 0, 0, 0, 0, 0],
      // 当前显示的Tab
      currentTab: 'business_license',
      // 导航
      navFrist: [
        {
          name: '营业执照信息',
          type: 1
        }
      ],
      // 点击第几个Tab页
      clickIndex: 1,
      // 提交确认弹窗
      isCommit: false,

      // 当前操作状态：1新增 2修改
      operation: 1,
      // 1经销商 or 2厂家直销
      type: null,
      // 选择供应商类型弹窗
      fristComingStatus: false,
      supplierType: '1',

      // 以下是权限按钮显示
      deleteAble: false, // 删除权限
      insertAble: false, // 插入权限
      modifyAble: false, // 修改权限
      searchAble: false, // 查找权限
      is_approved: null
    }
  },
  created() {
    // 获取供应商信息
    this.getSupplier()
    this.getRightVisibal()
      
  },
  methods: {
    /**
     * 校验部分
     * */
    // 校验 营业执照信息
    verify1() {
      // 校验必选项
      let bar = this.currentTab !== 'business_license' ? ' 营业执照信息 中的' : ''
      if (!this.business_license.license_file_name) {
        this.$Message.warning('请上传' + bar + '营业执照')
        return
      }
      if (!this.business_license.company_name) {
        this.$Message.warning('请填写' + bar + '企业名称')
        return
      }
      if (!this.business_license.unified_code) {
        this.$Message.warning('请填写' + bar + '统一社会信用代码')
        return
      }
      if (!this.business_license.legal_person) {
        this.$Message.warning('请填写' + bar + '法人')
        return
      }
      if (!this.business_license.business_start_date) {
        this.$Message.warning('请填写' + bar + '营业期限')
        return
      }
      if (this.business_license.is_long_term == 0) {
        if (!this.business_license.business_end_date) {
          this.$Message.warning('请填写' + bar + '营业期限')
          return
        }
      }
      if (!this.business_license.registration_date) {
        this.$Message.warning('请填写' + bar + '登记日期')
        return
      }
      if (!this.business_license.business_scope) {
        this.$Message.warning('请填写' + bar + '经营范围')
        return
      }
      if (!this.business_license.residence) {
        this.$Message.warning('请填写' + bar + '住所')
        return
      }
      if (!this.business_license.data_type || !this.business_license.data_type.length) {
        this.$Message.warning('请填写' + bar + '资料维护')
        return
      }
      this.verifyIsSend[0] = 1
    },
    // 校验 生产许可证
    verify2() {
      // 剔除空字符串的属性
      // for (let name in this.production_license) {
      //   if (this.production_license[name] === null) {
      //     delete this.production_license[name]
      //   }
      // }
      // 校验必选项
      let bar = this.currentTab !== 'production_license' ? ' 生产许可证 中的' : ''
      if (!this.production_license.license_file_name) {
        this.$Message.warning('请上传' + bar + '生产许可证')
        return
      }
      if (!this.production_license.business_start_date) {
        this.$Message.warning('请填写' + bar + '有效期限')
        return
      }
      if (!this.production_license.business_end_date) {
        this.$Message.warning('请填写' + bar + '有效期限')
        return
      }
      if (!this.production_license.company_name) {
        this.$Message.warning('请填写' + bar + '企业名称')
        return
      }
      if (!this.production_license.legal_person) {
        this.$Message.warning('请填写' + bar + '法人')
        return
      }
      if (!this.production_license.production_address) {
        this.$Message.warning('请填写' + bar + '生产地址')
        return
      }
      if (!this.production_license.residence) {
        this.$Message.warning('请填写' + bar + '住所')
        return
      }
      if (!this.production_license.production_license_code) {
        this.$Message.warning('请填写' + bar + '许可证编号')
        return
      }
      if (!this.production_license.incharge_person) {
        this.$Message.warning('请填写' + bar + '企业负责人')
        return
      }
      if (!this.production_license.production_scope) {
        this.$Message.warning('请填写' + bar + '生产范围')
        return
      }
      if (!this.production_license.license_date) {
        this.$Message.warning('请填写' + bar + '发证日期')
        return
      }
      this.verifyIsSend[1] = 1
    },
    // 校验 生产备案凭证
    verify3() {
      // 剔除空字符串的属性
      // for (let name in this.production_registration_license) {
      //   if (this.production_registration_license[name] === null) {
      //     delete this.production_registration_license[name]
      //   }
      // }
      // 校验必选项
      let bar = this.currentTab !== 'production_registration_license' ? ' 生产备案凭证 中的' : ''
      if (!this.production_registration_license.license_file_name) {
        this.$Message.warning('请上传' + bar + '备案凭证')
        return
      }
      if (!this.production_registration_license.production_registration_license_code) {
        this.$Message.warning('请填写' + bar + '备案号')
        return
      }
      if (!this.production_registration_license.company_name) {
        this.$Message.warning('请填写' + bar + '企业名称')
        return
      }
      if (!this.production_registration_license.legal_person) {
        this.$Message.warning('请填写' + bar + '法人')
        return
      }
      if (!this.production_registration_license.incharge_person) {
        this.$Message.warning('请填写' + bar + '企业负责人')
        return
      }
      if (!this.production_registration_license.production_address) {
        this.$Message.warning('请填写' + bar + '生产地址')
        return
      }
      if (!this.production_registration_license.residence) {
        this.$Message.warning('请填写' + bar + '住所')
        return
      }
      if (!this.production_registration_license.production_scope) {
        this.$Message.warning('请填写' + bar + '生产范围')
        return
      }
      if (!this.production_registration_license.license_date) {
        this.$Message.warning('请填写' + bar + '备案日期')
        return
      }
      this.verifyIsSend[2] = 1
    },
    // 校验 经营许可证
    verify4() {
      // 剔除空字符串的属性
      // for (let name in this.operating_license) {
      //   if (this.operating_license[name] === null) {
      //     delete this.operating_license[name]
      //   }
      // }
      // 校验必选项
      let bar = this.currentTab !== 'operating_license' ? ' 经营许可证 中的' : ''
      if (!this.operating_license.license_file_name) {
        this.$Message.warning('请上传' + bar + '经营许可证')
        return
      }
      if (!this.operating_license.business_start_date) {
        this.$Message.warning('请填写' + bar + '有效期限')
        return
      }
      if (this.operating_license.is_long_term == 0) {
        if (!this.operating_license.business_end_date) {
          this.$Message.warning('请填写' + bar + '有效期限')
          return
        }
      }
      if (!this.operating_license.operating_license_code) {
        this.$Message.warning('请填写' + bar + '许可证编号')
        return
      }
      if (!this.operating_license.company_name) {
        this.$Message.warning('请填写' + bar + '企业名称')
        return
      }
      if (!this.operating_license.operating_type || this.operating_license.operating_type == 'undefined' || this.operating_license.operating_type == 'null') {
        this.$Message.warning('请填写' + bar + '经营方式')
        return
      }
      if (!this.operating_license.legal_person) {
        this.$Message.warning('请填写' + bar + '法人')
        return
      }
      if (!this.operating_license.incharge_person) {
        this.$Message.warning('请填写' + bar + '企业负责人')
        return
      }
      if (!this.operating_license.residence) {
        this.$Message.warning('请填写' + bar + '住所')
        return
      }
      if (!this.operating_license.premise) {
        this.$Message.warning('请填写' + bar + '经营场所')
        return
      }
      if (!this.operating_license.warehouse_address) {
        this.$Message.warning('请填写' + bar + '库房地址')
        return
      }
      if (!this.operating_license.scope_list || this.operating_license.scope_list.length == 0) {
        this.$Message.warning('请填写' + bar + '经营范围')
        return
      }
      this.verifyIsSend[3] = 1
    },
    // 校验 经营备案凭证
    verify5() {
      // 剔除空字符串的属性
      // for (let name in this.registration_license) {
      //   if (this.registration_license[name] === null) {
      //     delete this.registration_license[name]
      //   }
      // }
      // 校验必选项
      let bar = this.currentTab !== 'registration_license' ? ' 经营备案凭证 中的' : ''
      if (!this.registration_license.license_file_name) {
        this.$Message.warning('请上传' + bar + '备案凭证')
        return
      }
      if (!this.registration_license.registration_license_code) {
        this.$Message.warning('请填写' + bar + '备案号')
        return
      }
      if (!this.registration_license.registration_date) {
        this.$Message.warning('请填写' + bar + '备案日期')
        return
      }
      if (!this.registration_license.company_name) {
        this.$Message.warning('请填写' + bar + '企业名称')
        return
      }
      if (!this.registration_license.operating_type || this.registration_license.operating_type == 'undefined' || this.registration_license.operating_type == 'null') {
        this.$Message.warning('请填写' + bar + '经营方式')
        return
      }
      if (!this.registration_license.legal_person) {
        this.$Message.warning('请填写' + bar + '法人')
        return
      }
      if (!this.registration_license.incharge_person) {
        this.$Message.warning('请填写' + bar + '企业负责人')
        return
      }
      if (!this.registration_license.residence) {
        this.$Message.warning('请填写' + bar + '住所')
        return
      }
      if (!this.registration_license.premise) {
        this.$Message.warning('请填写' + bar + '经营场所')
        return
      }
      if (!this.registration_license.warehouse_address) {
        this.$Message.warning('请填写' + bar + '库房地址')
        return
      }
      if (!this.registration_license.scope_list || this.registration_license.scope_list.length == 0) {
        this.$Message.warning('请填写' + bar + '经营范围')
        return
      }
      this.verifyIsSend[4] = 1
    },
    // 校验 补充信息
    verify6() {
      // 剔除空字符串的属性
      // for (let name in this.supplementary) {
      //   if (this.supplementary[name] === null) {
      //     delete this.supplementary[name]
      //   }
      // }
      // 校验必选项
      let bar = this.currentTab !== 'supplementary' ? ' 补充信息 中的' : ''
      if (!this.supplementary.license_file_name) {
        this.$Message.warning('请上传' + bar + '法人授权书')
        return
      }
      if (!this.supplementary.name) {
        this.$Message.warning('请填写' + bar + '被授权人姓名')
        return
      }
      if (!this.supplementary.certid) {
        this.$Message.warning('请填写' + bar + '证件号码')
        return
      }
      if (!this.supplementary.phone) {
        this.$Message.warning('请填写' + bar + '手机号')
        return
      }
      if (!this.supplementary.email) {
        this.$Message.warning('请填写' + bar + '邮箱')
        return
      }
      if (!this.supplementary.license_start_date) {
        this.$Message.warning('请填写' + bar + '授权日期')
        return
      }
      if (this.supplementary.is_long_term == 0) {
        if (!this.supplementary.license_end_date) {
          this.$Message.warning('请填写' + bar + '授权日期')
          return
        }
      }
      if (!this.supplementary.invoice_head) {
        this.$Message.warning('请填写' + bar + '发票抬头')
        return
      }
      if (!this.supplementary.invoice_no) {
        this.$Message.warning('请填写' + bar + '税号')
        return
      }
      if (!this.supplementary.invoice_bank) {
        this.$Message.warning('请填写' + bar + '开户银行')
        return
      }
      if (!this.supplementary.invoice_bankno) {
        this.$Message.warning('请填写' + bar + '银行账号')
        return
      }
      this.verifyIsSend[5] = 1
    },
    // 提交前检验：营业执照信息 + 资料维护选中的Tab页 + 补充信息
    beforeCommit() {
      // 初始化 检验标记
      this.verifyIsSend = [0, 0, 0, 0, 0, 0]
      // 校验 营业执照信息
      this.verify1()
      if (!this.verifyIsSend[0]) return
      // 校验 资料维护选中的Tab页（Tab栏从左到右校验）
      if (this.business_license.data_type.includes('3')) {
        this.verify2()
        if (!this.verifyIsSend[1]) return
      }
      if (this.business_license.data_type.includes('4')) {
        this.verify3()
        if (!this.verifyIsSend[2]) return
      }
      if (this.business_license.data_type.includes('1')) {
        this.verify4()
        if (!this.verifyIsSend[3]) return
      }
      if (this.business_license.data_type.includes('2')) {
        this.verify5()
        if (!this.verifyIsSend[4]) return
      }
      // 校验 补充信息
      this.verify6()
      if (!this.verifyIsSend[5]) return
      // 提交确认弹窗
      this.isCommit = true
    },
    // 保存前检验：营业执照信息 + 当前Tab页
    beforeSave() {
      // 初始化 检验标记
      this.verifyIsSend = [0, 0, 0, 0, 0, 0]
      // 校验 营业执照信息
      this.verify1()
      if (!this.verifyIsSend[0]) return
      // 校验 当前Tab页
      switch (this.currentTab) {
        case 'production_license': // 校验 生产许可证
          this.verify2()
          if (!this.verifyIsSend[1]) return
          break
        case 'production_registration_license': // 校验 生产备案凭证
          this.verify3()
          if (!this.verifyIsSend[2]) return
          break
        case 'operating_license': // 校验 经营许可证
          this.verify4()
          if (!this.verifyIsSend[3]) return
          break
        case 'registration_license': // 校验 经营备案凭证
          this.verify5()
          if (!this.verifyIsSend[4]) return
          break
        case 'supplementary': // 校验 补充信息
          this.verify6()
          if (!this.verifyIsSend[5]) return
          break
        default:
          break
      }
      // -1保存
      this.save('-1')
    },

    /**
     * 保存、提交
     * */
    // 保存 or 提交（supplier_state: -1保存 1提交）
    save:debounce(function(supplier_state) {
      // 存放将发送的数据
      let data = {}
      // 同步数据
      // data = this.synchronousData(supplier_state)
      // 必传数据
      data.supplier_state = supplier_state // -1保存 1提交
      data.supplier_type = this.type // 供应商类型
      data.supplier_id = this.originalData.supplier_id // 供应商id
      /**
       * 分开发送
       * */
      // 信息状态 String -1保存 1提交
      // if (supplier_state == '1') {
      //   for (let item of this.navFrist) {
      //     // 如果 提交 则发送所有Tab页信息
      //     // [1营业执照信息, 2生产许可证, 3生产备案凭证, 4经营许可证, 5经营备案凭证, 6补充信息]
      //     switch (item.type){
      //       case 2:
      //         // 剔除空字符串的属性
      //         // for (let name in this.production_license) {
      //         //   if (this.production_license[name] === null) {
      //         //     delete this.production_license[name]
      //         //   }
      //         // }
      //         data.production_license = this.production_license
      //         break;
      //       case 3:
      //         // 剔除空字符串的属性
      //         // for (let name in this.production_registration_license) {
      //         //   if (this.production_registration_license[name] === null) {
      //         //     delete this.production_registration_license[name]
      //         //   }
      //         // }
      //         data.production_registration_license = this.production_registration_license
      //         break;
      //       case 4:
      //         // 剔除空字符串的属性
      //         // for (let name in this.operating_license) {
      //         //   if (this.operating_license[name] === null) {
      //         //     delete this.operating_license[name]
      //         //   }
      //         // }
      //         data.operating_license = this.operating_license
      //         break;
      //       case 5:
      //         // 剔除空字符串的属性
      //         // for (let name in this.registration_license) {
      //         //   if (this.registration_license[name] === null) {
      //         //     delete this.registration_license[name]
      //         //   }
      //         // }
      //         data.registration_license = this.registration_license
      //         break;
      //       default:
      //         break;
      //     }
      //   }
      //   data.business_license = this.business_license // 营业执照信息
      //   data.supplementary = this.supplementary // 补充信息
      // } else {
      //   // 如果 保存 则发送 营业执照信息 和 当前Tab页信息
      //   data.business_license = this.business_license // 营业执照信息
      //   data[this.currentTab] = this[this.currentTab] // 当前Tab页信息
      // }
      /**
       * 一起发送
       * */
      data.business_license = this.business_license // 营业执照信息
      delete data.business_license.license_file_key_url
      delete data.business_license.contract_file_key_url
      // 如果选择了长期则截止日期传'9999-12-31'，方便后端排序
      if (data.business_license.is_long_term) {
        data.business_license.business_end_date = '9999-12-31'
      }
      for (let item of this.navFrist) {
        // [1营业执照信息, 2生产许可证, 3生产备案凭证, 4经营许可证, 5经营备案凭证, 6补充信息]
        switch (item.type) {
          case 2:
            // 剔除空字符串的属性
            for (let name in this.production_license) {
              if (this.production_license[name] === null) {
                delete this.production_license[name]
              }
            }
            data.production_license = this.production_license
            delete data.production_license.license_file_key_url
            break
          case 3:
            // 剔除空字符串的属性
            for (let name in this.production_registration_license) {
              if (this.production_registration_license[name] === null) {
                delete this.production_registration_license[name]
              }
            }
            data.production_registration_license = this.production_registration_license
            delete data.production_registration_license.license_file_key_url
            break
          case 4:
            // 剔除空字符串的属性
            for (let name in this.operating_license) {
              if (this.operating_license[name] === null || this.operating_license[name] == 'undefined' || this.operating_license[name] == 'null') {
                delete this.operating_license[name]
              }
            }
            data.operating_license = this.operating_license
            delete data.operating_license.license_file_key_url
            // 如果选择了长期则截止日期传'9999-12-31'，方便后端排序
            if (data.operating_license.is_long_term) {
              data.operating_license.business_end_date = '9999-12-31'
            }
            if (!data.operating_license.business_end_date) {
              data.operating_license.business_end_date = null
            }
            break
          case 5:
            // 剔除空字符串的属性
            for (let name in this.registration_license) {
              if (this.registration_license[name] === null || this.registration_license[name] == 'undefined' || this.registration_license[name] == 'null') {
                delete this.registration_license[name]
              }
            }
            data.registration_license = this.registration_license
            delete data.registration_license.license_file_key_url
            break
          case 6:
            // 剔除空字符串的属性
            for (let name in this.supplementary) {
              if (this.supplementary[name] === null) {
                delete this.supplementary[name]
              }
            }
            data.supplementary = this.supplementary
            delete data.supplementary.license_file_key_url
            delete data.supplementary.company_name
            // 如果选择了长期则截止日期传'9999-12-31'，方便后端排序
            if (data.supplementary.is_long_term) {
              data.supplementary.license_end_date = '9999-12-31'
            }
            break
          default:
            break
        }
      }
      // business_license = e.factory_business
      console.log(this.$refs.businessLicenseInfo.business_license)
      delete data.business_license.contract_file_key_url
      this.$refs.additionalInfo.supplementary.invoice_head = this.$refs.businessLicenseInfo.business_license.company_name
      this.$refs.additionalInfo.supplementary.invoice_no = this.$refs.businessLicenseInfo.business_license.unified_code
      this.$refs.additionalInfo.supplementary.company_address = this.$refs.businessLicenseInfo.business_license.residence
      // 发送
      this.$http.post(this.$api.supplierSave, data, true).then(res => {
        this.isCommit = false
        if (res.status) {
          this.$Message.success(res.message)
          this.getSupplier()
        }
      })
      sessionStorage.setItem('updateList','0')
    }, 1000, true),

    /**
     * 改动数据
     * */
    // 当前Tab页数据改动时同步数据
    synchronousData() {
      // 补充信息Tab页没有同步的信息
      // if (this.currentTab == 'supplementary') return
      // 暂存待发送的数据
      let data = {}
      // 暂存待同步的数据
      let syncData = {}
      if (this[this.currentTab]['company_name']) {
        syncData.company_name = this[this.currentTab]['company_name'] // 企业名称
      }
      if (this[this.currentTab]['legal_person']) {
        syncData.legal_person = this[this.currentTab]['legal_person'] // 法人
      }
      if (this[this.currentTab]['residence']) {
        syncData.residence = this[this.currentTab]['residence'] // 住所
      }
      if (this[this.currentTab]['operating_type']) {
        syncData.operating_type = this[this.currentTab]['operating_type'] // 经营方式
      }
      if (this[this.currentTab]['incharge_person']) {
        syncData.incharge_person = this[this.currentTab]['incharge_person'] // 企业负责人
      }
      if (this[this.currentTab]['production_address']) {
        syncData.production_address = this[this.currentTab]['production_address'] // 生产地址
      }
      if (this[this.currentTab]['production_scope']) {
        syncData.production_scope = this[this.currentTab]['production_scope'] // 生产范围
      }
      if (this[this.currentTab]['premise']) {
        syncData.premise = this[this.currentTab]['premise'] // 经营场所
      }
      if (this[this.currentTab]['warehouse_address']) {
        syncData.warehouse_address = this[this.currentTab]['warehouse_address'] // 库房地址
      }
      // 如果不是新增，生成待发送的数据
      if (this.operation != 1) {
        let dataType = this.business_license.data_type
        if (dataType.includes('1') && JSON.stringify(this.originalData.operating_license) != '{}') {
          data.operating_license = syncData
        }
        if (dataType.includes('2') && JSON.stringify(this.originalData.registration_license) != '{}') {
          data.registration_license = syncData
        }
        if (dataType.includes('3') && JSON.stringify(this.originalData.production_license) != '{}') {
          data.production_license = syncData
        }
        if (dataType.includes('4') && JSON.stringify(this.originalData.production_registration_license) != '{}') {
          data.production_registration_license = syncData
        }
        // 单独处理 营业执照信息
        if (syncData.company_name) {
          this.business_license.company_name = syncData.company_name
        }
        if (syncData.legal_person) {
          this.business_license.legal_person = syncData.legal_person
        }
        if (syncData.residence) {
          this.business_license.residence = syncData.residence
        }
      }
      return data
    },
    // 页面信息同步
    pageSyncData(bar, syncField) {
      // 补充信息Tab页没有要同步的信息
      // if (this.currentTab == 'supplementary') return
      for (let item of this.navFrist) {
        // [1营业执照信息, 2生产许可证, 3生产备案凭证, 4经营许可证, 5经营备案凭证, 6补充信息]
        switch (item.type) {
          case 1:
            if (syncField[0] in this.business_license) {
              this.business_license[syncField[0]] = syncField[1]
            }
            break
          case 2:
            if (syncField[0] in this.production_license) {
              this.production_license[syncField[0]] = syncField[1]
            }
            break
          case 3:
            if (syncField[0] in this.production_registration_license) {
              this.production_registration_license[syncField[0]] = syncField[1]
            }
            break
          case 4:
            if (syncField[0] in this.operating_license) {
              this.operating_license[syncField[0]] = syncField[1]
            }
            break
          case 5:
            if (syncField[0] in this.registration_license) {
              this.registration_license[syncField[0]] = syncField[1]
            }
            break
          case 6:
            if (syncField[0] in this.supplementary) {
              this.$refs.additionalInfo.deep()
              this.supplementary[syncField[0]] = syncField[1]
            }
            break
          default:
            break
        }
      }
    },

    // 监听子组件传来的数据
    information(bar, foo, syncField) {
      // 接收子组件的传值
      switch (bar) {
        case 'business_license':
          this.business_license = foo
          break
        case 'production_license':
          this.production_license = foo
          break
        case 'operating_license':
          this.operating_license = foo
          break
        case 'registration_license':
          this.registration_license = foo
          break
        case 'supplementary':
          this.supplementary = foo
          break
        default:
          break
      }
      // console.log(bar, foo, syncField, '78')
      // 同步各Tab页部分数据
      if (syncField) {
        this.pageSyncData(bar, syncField)
      }
    },

    /**
     * 请求数据
     * */
    // 请求到的-处理日期格式
    processingDate() {
      // 营业执照信息-时间处理
      this.business_license.establish_date = this.business_license.establish_date ? this.$moment(new Date(this.business_license.establish_date * 1000)).format('YYYY-MM-DD') : null
      this.business_license.business_start_date = this.business_license.business_start_date ? this.$moment(new Date(this.business_license.business_start_date * 1000)).format('YYYY-MM-DD') : null
      this.business_license.business_end_date = this.business_license.business_end_date ? this.$moment(new Date(this.business_license.business_end_date * 1000)).format('YYYY-MM-DD') : null
      // this.business_license.business_end_date = this.business_license.business_end_date ? this.$moment.unix(this.business_license.business_end_date).format('YYYY-MM-DD') : null
      this.business_license.registration_date = this.business_license.registration_date ? this.$moment(new Date(this.business_license.registration_date * 1000)).format('YYYY-MM-DD') : null

      // 生产许可证-时间处理
      this.production_license.business_start_date = this.production_license.business_start_date ? this.$moment(new Date(this.production_license.business_start_date * 1000)).format('YYYY-MM-DD') : null
      this.production_license.business_end_date = this.production_license.business_end_date ? this.$moment(new Date(this.production_license.business_end_date * 1000)).format('YYYY-MM-DD') : null
      this.production_license.license_date = this.production_license.license_date ? this.$moment(new Date(this.production_license.license_date * 1000)).format('YYYY-MM-DD') : null

      // 生产备案凭证-时间处理
      this.production_registration_license.license_date = this.production_registration_license.license_date ? this.$moment(new Date(this.production_registration_license.license_date * 1000)).format('YYYY-MM-DD') : null

      // 经营许可证-时间处理
      this.operating_license.business_start_date = this.operating_license.business_start_date ? this.$moment(new Date(this.operating_license.business_start_date * 1000)).format('YYYY-MM-DD') : null
      this.operating_license.business_end_date = this.operating_license.business_end_date ? this.$moment(new Date(this.operating_license.business_end_date * 1000)).format('YYYY-MM-DD') : null

      // 经营备案凭证-时间处理
      this.registration_license.registration_date = this.registration_license.registration_date ? this.$moment(new Date(this.registration_license.registration_date * 1000)).format('YYYY-MM-DD') : null

      // 补充信息-时间处理
      this.supplementary.license_start_date = this.supplementary.license_start_date ? this.$moment(new Date(this.supplementary.license_start_date * 1000)).format('YYYY-MM-DD') : null
      this.supplementary.license_end_date = this.supplementary.license_end_date ? this.$moment(new Date(this.supplementary.license_end_date * 1000)).format('YYYY-MM-DD') : null
    },
    // 请求到的-处理 修改 前时Tab页需同步回显的数据
    processingSyncData() {
      // 待同步回显的信息
      let syncData = {}
      // 获取需同步的数据
      if (JSON.stringify(this.originalData.business_license) != '{}') {
        // 营业执照信息
        syncData.company_name = this.business_license.company_name // 企业名称
        syncData.legal_person = this.business_license.legal_person // 法人
        syncData.residence = this.business_license.residence // 住所
      }
      if (JSON.stringify(this.originalData.production_license) != '{}') {
        // 生产许可证
        syncData.incharge_person = this.production_license.incharge_person // 企业负责人
        syncData.production_address = this.production_license.production_address // 生产地址
        syncData.production_scope = this.production_license.production_scope // 生产范围
      }
      if (JSON.stringify(this.originalData.production_registration_license) != '{}') {
        // 生产备案凭证
        syncData.incharge_person = this.production_registration_license.incharge_person // 企业负责人
        syncData.production_address = this.production_registration_license.production_address // 生产地址
        syncData.production_scope = this.production_registration_license.production_scope // 生产范围
      }
      if (JSON.stringify(this.originalData.operating_license) != '{}') {
        // 经营许可证
        syncData.operating_type = this.operating_license.operating_type || null // 经营方式
        syncData.incharge_person = this.operating_license.incharge_person // 企业负责人
        syncData.premise = this.operating_license.premise // 经营场所
        syncData.warehouse_address = this.operating_license.warehouse_address // 库房地址
      }
      if (JSON.stringify(this.originalData.registration_license) != '{}') {
        // 经营备案凭证
        syncData.operating_type = this.registration_license.operating_type || null // 经营方式
        syncData.incharge_person = this.registration_license.incharge_person // 企业负责人
        syncData.premise = this.registration_license.premise // 经营场所
        // syncData.warehouse_address = this.registration_license.warehouse_address // 库房地址
      }
      // 如果某个Tab页没有数据则同步回显部分信息（补充信息没有要同步的数据）
      if (JSON.stringify(this.originalData.production_license) == '{}') {
        this.production_license = {
          company_name: syncData.company_name,
          legal_person: syncData.legal_person,
          residence: syncData.residence,
          incharge_person: syncData.incharge_person,
          production_address: syncData.production_address,
          production_scope: syncData.production_scope
        }
      }
      if (JSON.stringify(this.originalData.production_registration_license) == '{}') {
        this.production_registration_license = {
          company_name: syncData.company_name,
          legal_person: syncData.legal_person,
          residence: syncData.residence,
          incharge_person: syncData.incharge_person,
          production_address: syncData.production_address,
          production_scope: syncData.production_scope
        }
      }
      if (JSON.stringify(this.originalData.operating_license) == '{}') {
        this.operating_license = {
          company_name: syncData.company_name,
          legal_person: syncData.legal_person,
          residence: syncData.residence,
          incharge_person: syncData.incharge_person,
          operating_type: syncData.operating_type,
          premise: syncData.premise,
          warehouse_address: syncData.warehouse_address
        }
      }
      if (JSON.stringify(this.originalData.registration_license) == '{}') {
        this.registration_license = {
          company_name: syncData.company_name,
          legal_person: syncData.legal_person,
          residence: syncData.residence,
          incharge_person: syncData.incharge_person,
          operating_type: syncData.operating_type,
          premise: syncData.premise,
          warehouse_address: syncData.warehouse_address
        }
      }
    },
    // 获取供应商信息
    getSupplier() {
      this.$http.get(this.$api.getSupplier, { is_supplier_side: '1' }, true).then(res => {
        // 判断当前是什么操作：新增 or 修改
        console.log(res.data,'数据')
        if (!res.data) {
          this.$root.eventHub.$emit('supplier_id', null)
          this.operation = 1 // 新增
          if (sessionStorage.getItem('supplierType')) {
            this.type = Number(sessionStorage.getItem('supplierType'))
          } else {
            this.fristComingStatus = true
          }
          // 定制导航
          this.navFrist = []
          this.navFrist.push({
            name: '营业执照信息',
            type: 1
          })
        } else {
          this.$root.eventHub.$emit('supplier_id', res.data.supplier_id)
          this.operation = 2 // 修改
          this.type = res.data.supplier_type // 供应商类型
          // 存储请求到的原始数据
          this.originalData = JSON.parse(JSON.stringify(res.data))
          // 各Tab页数据赋值
          this.business_license = res.data.business_license
          this.production_license = res.data.production_license
          this.production_registration_license = res.data.production_registration_license
          this.operating_license = res.data.operating_license
          this.registration_license = res.data.registration_license
          this.supplementary = res.data.supplementary
          this.is_approved = res.data.is_approved
          // this.supplementary.company_name = this.business_license.company_name
          // 新选择的 资料维护 项没有数据，此时部分相同的数据同样需要回显
          this.processingSyncData()
          // 处理日期格式
          this.processingDate()
          // 定制导航
          if (this.operation === 2) {
            this.navFrist = []
            if (this.type === '1') {
              // 经销商
              this.navFrist.push({
                name: '营业执照信息',
                type: 1
              })
              if (this.business_license.data_type.includes('1')) {
                this.navFrist.push({
                  name: '经营许可证',
                  type: 4
                })
              }
              if (this.business_license.data_type.includes('2')) {
                this.navFrist.push({
                  name: '经营备案凭证',
                  type: 5
                })
              }
              this.navFrist.push({
                name: '补充信息',
                type: 6
              })
            } else {
              // 厂家直销
              this.navFrist.push({
                name: '营业执照信息',
                type: 1
              })
              if (this.business_license.data_type.includes('3')) {
                this.navFrist.push({
                  name: '生产许可证',
                  type: 2
                })
              }
              if (this.business_license.data_type.includes('4')) {
                this.navFrist.push({
                  name: '生产备案凭证',
                  type: 3
                })
              }
              if (this.business_license.data_type.includes('1')) {
                this.navFrist.push({
                  name: '经营许可证',
                  type: 4
                })
              }
              if (this.business_license.data_type.includes('2')) {
                this.navFrist.push({
                  name: '经营备案凭证',
                  type: 5
                })
              }
              this.navFrist.push({
                name: '补充信息',
                type: 6
              })
            }
          }
        }
      })
    },

    /**
     * 杂七杂八
     * */
    // 模版导入成功后需重新请求
    importSuccess(res) {
      
      console.log(res)
      if (res.data.business_license) {
        res.data.business_license.business_end_date = res.data.business_license.business_end_date ? this.$moment.unix(res.data.business_license.business_end_date).format('YYYY-MM-DD') : null
        res.data.business_license.business_start_date = res.data.business_license.business_start_date ? this.$moment.unix(res.data.business_license.business_start_date).format('YYYY-MM-DD') : null
        res.data.business_license.establish_date = res.data.business_license.establish_date ? this.$moment.unix(res.data.business_license.establish_date).format('YYYY-MM-DD') : null
        res.data.business_license.registration_date = res.data.business_license.registration_date ? this.$moment.unix(res.data.business_license.registration_date).format('YYYY-MM-DD') : null
        this.business_license = res.data.business_license
      }
      if (res.data.production_license) {
        this.production_license = res.data.production_license
      }
      if (res.data.production_registration_license) {
        this.production_registration_license = res.data.production_registration_license
      }

      if (res.data.operating_license) {
        res.data.operating_license.business_end_date = res.data.operating_license.business_end_date ? this.$moment.unix(res.data.operating_license.business_end_date).format('YYYY-MM-DD') : null
        res.data.operating_license.business_start_date = res.data.operating_license.business_start_date ? this.$moment.unix(res.data.operating_license.business_start_date).format('YYYY-MM-DD') : null
        this.operating_license = res.data.operating_license
      }
      if (res.data.registration_license) {
        res.data.registration_license.registration_date = res.data.registration_license.registration_date ? this.$moment.unix(res.data.registration_license.registration_date).format('YYYY-MM-DD') : null
        this.registration_license = res.data.registration_license
      }

      if (res.data.supplementary) {
        res.data.supplementary.license_end_date = res.data.supplementary.license_end_date ? this.$moment.unix(res.data.supplementary.license_end_date).format('YYYY-MM-DD') : null
        res.data.supplementary.license_start_date = res.data.supplementary.license_start_date ? this.$moment.unix(res.data.supplementary.license_start_date).format('YYYY-MM-DD') : null
        this.supplementary = res.data.supplementary
      }
    },
    // 切换Tab
    clickNav(type) {
      this.clickIndex = type
      // 校正 currentTab
      switch (this.clickIndex) {
        case 1:
          this.currentTab = 'business_license'
          break
        case 2:
          this.currentTab = 'production_license'
          break
        case 3:
          this.currentTab = 'production_registration_license'
          break
        case 4:
          this.currentTab = 'operating_license'
          break
        case 5:
          this.currentTab = 'registration_license'
          break
        case 6:
          this.currentTab = 'supplementary'
          break
        default:
          break
      }
    },
    // 选择供应商类型后下一步
    nextStep() {
      sessionStorage.setItem('supplierType', this.supplierType)
      this.fristComingStatus = false
      this.type = Number(this.supplierType) // 供应商类型
      // this.$router.go(0)
    },
    // 获取按钮显示权限方法
    getRightVisibal() {
      let navName = this.$route.meta.navName
      let rightStr = JSON.parse(window.sessionStorage.getItem(navName))
      this.deleteAble = rightStr.delete
      this.insertAble = rightStr.insert
      this.modifyAble = rightStr.modify
      this.searchAble = rightStr.search
    }
  }
}
</script>

<style scoped lang="less">
.pagePadding {
  padding: 0 !important;
  background: #f0f0f0 !important;
  height: 100px;
  display: flex;
  flex-direction: column;

  .btmDiv {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 20px 25px 30px 25px;

    .formDiv {
      flex: 1;
      overflow-y: auto;
      margin-bottom: 30px;
    }

    .saveDiv {
      height: 36px;
      position: relative;
    }
  }

  .topNavDiv {
    height: 42px;

    li {
      float: left;
      width: 120px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      margin-right: 2px;
      background: #e2e2e2;
      color: #999999;
      font-size: 15px;
    }

    .clickActive {
      background: #fff;
      color: #008cf8;
      font-weight: bold;
    }
  }
}
.noticeText{
  position: absolute;
  bottom: -20px;
  color: red;
  right: -20px;
  font-size: 14px;
}
</style>
