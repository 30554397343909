<template>
  <div>
    <Upload v-if="apiSrc" id="iviewUp" :show-upload-list="false" :on-progress="handleProgressQuill" :on-success="handleSuccessQuill" :on-error="handleErrorQuill" :format="['xlsx']" :headers="header" :multiple="multiple" name="xlsx_file" :max-size="2048" :action="url" :data="{ supplier_type: supplier_type }" :on-format-error="onFormatError">
      {{ label }}
    </Upload>
    <Spin fix v-show="loading">
      <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
      <!-- <div>上传中...</div> -->
    </Spin>
  </div>
</template>

<script>
export default {
  name: 'formUpload',
  props: {
    label: String, // 按钮文字
    apiSrc: String, // 上传接口
    supplier_type: String || '', // 附加参数
    multiple: Boolean || false
  },
  data() {
    return {
      loading: false,
      url: 'example',
      header: ''
    }
  },
  created() {
    this.url = this.apiSrc
    this.header = {
      Authorization: 'Bearer ' + localStorage.getItem('ohoqicbi==')
    }
  },
  methods: {
    // 文件类型校验
    onFormatError() {
      this.$Message.error('只允许上传xlsx格式的文件')
    },
    // 上传失败
    handleErrorQuill() {
      this.loading = false
      this.$Message.error('文件上传失败')
    },
    // 上传成功
    handleSuccessQuill(res) {
      if (!res.status) {
        this.$Message.warning(res.message)
      } else {
        this.$Message.success('模版导入成功')
        this.$emit('importSuccess', res)
      }
      this.loading = false
    },
    // 上传中
    handleProgressQuill() {
      this.loading = true
    }
  }
}
</script>

<style scoped lang="less">
#iviewUp {
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
  border: none;
  text-align: center;
  box-sizing: border-box;
}
/deep/ .ivu-upload-select {
  width: 100% !important;
}
.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}

@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.demo-spin-col {
  height: 100px;
  position: relative;
  border: 1px solid #eee;
}
</style>
